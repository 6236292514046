<template>
  <section>
    <el-form label-width="80px" style="min-width:700px">
      <el-form-item label="url">
        <el-input v-model="url" placeholder="TODO"></el-input>
      </el-form-item>
      <el-form-item label="userid">
        <el-input v-model="userId" placeholder="TODO"></el-input>
      </el-form-item>
      <el-form-item label="sess">
        <el-input v-model="sess" placeholder="TODO"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onQuery">查询</el-button>
        <el-button type="primary" @click="onExportWord">导出word</el-button>
      </el-form-item>
    </el-form>
    <div id="question-list">
      <div v-for="(item, index) in questionList" :key="index">
        <div v-html="item.content"></div>
        <div v-if="item.teachingPlan" v-html="item.teachingPlan.content"></div>
        <div
          v-if="item.transcription"
          v-html="item.transcription.content"
        ></div>
      </div>
    </div>
  </section>
</template>

<script>
import { crawlerApi } from "@/api/crawlerApi";
import msg from "@/util/msg.js";
export default {
  data() {
    return {
      url:
        "https://tiku.fenbi.com/api/jszgzmsxxyw/solutions?ids=2684011,2684048,2684049,3542668,3542670,3542671,3542675,3542676,3542678",
      sess:
        "vweIJMv66jERG4RSGe/4XArvefMYoxl+6oh1JHqdfdLXrekaJD40WCJ7PPJ42hudfJFAYWt8WKHqY1SjltTLMOr1fMWFKnMeTD2sZI0loFk=",
      userId: "74777377",
      questionList: [],
    };
  },
  mounted() {},
  methods: {
    onQuery() {
      if (!this.url) {
        msg.warn("url 不允许为空");
        return;
      }
      if (!this.sess) {
        msg.warn("sess 不允许为空");
        return;
      }
      if (!this.userId) {
        msg.warn("userId 不允许为空");
        return;
      }
      crawlerApi
        .jzInterview(this.url, this.userId, encodeURIComponent(this.sess))
        .then((ret) => {
          let { code, data } = ret;
          if (code == 0) {
            this.questionList = data;
            this.wrapAccessory(this.questionList);
          }
        })
        .catch((err) => {
          console.log("err", err);
          msg.error("出错了" + err);
        });
    },
    wrapAccessory(questionList) {
      for (let item of questionList) {
        let teachingPlan = {};
        let transcription = {};
        if (item.solutionAccessories) {
          for (let label of item.solutionAccessories) {
            if (label.label && label.label == "swdt") {
              teachingPlan = label;
            } else if (label.label && label.label == "sfdt") {
              transcription = label;
            }
          }
        }
        if (teachingPlan) {
          item.teachingPlan = teachingPlan;
        }
        if (transcription) {
          item.transcription = transcription;
        }
      }
    },
    onExportWord() {
      var header =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
        "xmlns:w='urn:schemas-microsoft-com:office:word' " +
        "xmlns='http://www.w3.org/TR/REC-html40'>" +
        "<head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>";
      var footer = "</body></html>";
      var sourceHTML =
        header + document.getElementById("question-list").innerHTML + footer;

      var source =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(sourceHTML);
      var fileDownload = document.createElement("a");
      document.body.appendChild(fileDownload);
      fileDownload.href = source;
      fileDownload.download = "document.docx";
      fileDownload.click();
      document.body.removeChild(fileDownload);
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
#question-list {
  text-align: left;
}
</style>
